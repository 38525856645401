// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <MAvatar
      src={user.avatar_url}
      alt={user.firstname}
      color={user.avatar_url ? 'default' : createAvatar(user.firstname).color}
      {...other}
    >
      {createAvatar(user.firstname).name}
    </MAvatar>
  );
}
