// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  changePassword: path(ROOTS_AUTH, '/change-password')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking/list'),
    profile: path(ROOTS_DASHBOARD, '/profile')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/profile'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    inactive: path(ROOTS_DASHBOARD, '/user/inactive'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  purchase: {
    root: path(ROOTS_DASHBOARD, '/purchase'),
    list: path(ROOTS_DASHBOARD, '/purchase/list'),
    newPurchase: path(ROOTS_DASHBOARD, '/purchase/new')
  },
  pin: {
    root: path(ROOTS_DASHBOARD, '/pin'),
    list: path(ROOTS_DASHBOARD, '/pin/list')
  },
  booking: {
    root: path(ROOTS_DASHBOARD, '/booking'),
    list: path(ROOTS_DASHBOARD, '/booking/list'),
    admin: path(ROOTS_DASHBOARD, '/booking/admin'),
    new: path(ROOTS_DASHBOARD, '/booking/new'),
    show: path(ROOTS_DASHBOARD, '/booking/:id')
  },
  setting: {
    root: path(ROOTS_DASHBOARD, '/settings')
  },
  receipt: {
    root: path(ROOTS_DASHBOARD, '/receipts')
  }
};
