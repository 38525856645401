import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  bookingList: [],
  bookingListCount: 0,
  myBookingList: [],
  myBookingListCount: 0,
  currentReservation: null,
  summary: {}
};

const slice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // BOOKING LIST
    getBookingListSuccess(state, action) {
      state.isLoading = false;
      state.bookingList = action.payload.rows;
      state.bookingListCount = action.payload.count;
    },

    getBookingDetailSuccess(state, action) {
      state.isLoading = false;
      state.currentReservation = action.payload;
    },

    getMyBookingListSuccess(state, action) {
      state.isLoading = false;
      state.myBookingList = action.payload.rows;
      state.myBookingListCount = action.payload.count;
    },

    getBookingSummarySuccess(state, action) {
      state.summary = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBookingList(q = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/booking', {
        params: q
      });
      dispatch(slice.actions.getBookingListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMyBookingList(q = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/booking', {
        params: q
      });
      dispatch(slice.actions.getMyBookingListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSummary() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get('/booking/summary', {});
    dispatch(slice.actions.getBookingSummarySuccess(response.data));
  };
}

export function getReservation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(`/booking/${id}`, {});
    dispatch(slice.actions.getBookingDetailSuccess(response.data));
  };
}
