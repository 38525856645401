import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  purchaseList: [],
  purchaseListCount: 0,
  currentPurchase: null
};

const slice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE USERS
    getPurchaseListSuccess(state, action) {
      state.isLoading = false;
      state.purchaseList = action.payload.rows;
      state.purchaseListCount = action.payload.count;
    },

    getCurrentPurchaseSuccess(state, action) {
      state.isLoading = false;
      state.currentPurchase = action.payload;
    },

    resetPurchase(state, action) {
      state.currentPurchase = null;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteUser } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getPurchaseList(q = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/purchases', {
        params: q
      });
      dispatch(slice.actions.getPurchaseListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPurchase(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/purchases/${id}`);
      dispatch(slice.actions.getCurrentPurchaseSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetPurchase() {
  return async (dispatch) => {
    dispatch(slice.actions.resetPurchase());
  };
}
