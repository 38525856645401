export function formatUserRole(role) {
  if (role === 'admin') {
    return 'Admin';
  }

  if (role === 'direttivo') {
    return 'Direttivo';
  }

  return 'Socio';
}
