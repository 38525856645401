import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isBefore } from 'date-fns';

import { PATH_DASHBOARD } from '../routes/paths';
// hooks
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

SubscribedGuard.propTypes = {
  children: PropTypes.node
};

export default function SubscribedGuard({ children }) {
  const { user } = useAuth();

  if (isBefore(new Date(user.subscription_expiration_date), new Date())) {
    return <Navigate to={PATH_DASHBOARD.general.banking} />;
  }

  return <>{children}</>;
}
