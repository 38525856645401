// routes
import homeFill from '@iconify/icons-eva/home-fill';
import unlockFill from '@iconify/icons-eva/unlock-fill';
import settingFill from '@iconify/icons-eva/settings-fill';
import receiptFill from '@iconify/icons-eva/credit-card-fill';
import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const getBoxIcon = (icon) => <Icon icon={icon} width={22} height={22} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Cruscotto',
    items: [
      {
        title: 'Home',
        path: PATH_DASHBOARD.general.app,
        icon: getBoxIcon(homeFill)
      },
      { title: 'Credito', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      { title: 'Le mie prenotazioni', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
      { title: 'Profilo', path: PATH_DASHBOARD.general.profile, icon: ICONS.user }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Gestione',
    roles: ['admin', 'direttivo'],
    items: [
      // MANAGEMENT : USER
      {
        title: 'Soci attivi',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user
      },

      // MANAGEMENT : USER
      {
        title: 'Soci Inattivi',
        path: PATH_DASHBOARD.user.inactive,
        icon: ICONS.user
      },
      // MANAGEMENT : Booking
      {
        title: 'Prenotazioni soci',
        path: PATH_DASHBOARD.booking.admin,
        icon: ICONS.cart
      },

      {
        title: 'Codici chiave',
        path: PATH_DASHBOARD.pin.root,
        icon: getBoxIcon(unlockFill)
      },

      {
        title: 'Ricevute',
        path: PATH_DASHBOARD.receipt.root,
        icon: getBoxIcon(receiptFill),
        roles: ['admin']
      },

      {
        title: 'Acquisti',
        path: PATH_DASHBOARD.purchase.list,
        icon: getBoxIcon(receiptFill),
        roles: ['admin']
      },

      {
        title: 'Impostazioni',
        path: PATH_DASHBOARD.setting.root,
        icon: getBoxIcon(settingFill),
        roles: ['admin']
      }
    ]
  }
];

export default sidebarConfig;
