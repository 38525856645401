import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import SubscribedGuard from '../guards/SubscribedGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';

// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'change-password',
          element: (
            <AuthGuard>
              <ChangePassword />
            </AuthGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'set-password', element: <SetNewPassword /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        {
          path: 'app',
          element: (
            <SubscribedGuard>
              <GeneralApp />
            </SubscribedGuard>
          )
        },
        { path: 'banking', element: <GeneralBanking /> },
        {
          path: 'booking',
          children: [
            { element: <Navigate to="/dashboard/booking/list" replace /> },
            {
              path: 'list',
              element: (
                <SubscribedGuard>
                  <UserBooking />
                </SubscribedGuard>
              )
            },
            {
              path: 'admin',
              element: (
                <RoleBasedGuard accessibleRoles={['admin', 'direttivo']}>
                  <SubscribedGuard>
                    <GeneralBooking />
                  </SubscribedGuard>
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <SubscribedGuard>
                  <BookingCreate />
                </SubscribedGuard>
              )
            },
            {
              path: ':id',
              element: (
                <SubscribedGuard>
                  <BookingShow />
                </SubscribedGuard>
              )
            }
          ]
        },

        { path: 'profile', element: <UserAccount /> },

        {
          path: 'settings',
          element: (
            <RoleBasedGuard accessibleRoles={['admin']}>
              <Setting />
            </RoleBasedGuard>
          )
        },

        {
          path: 'receipts',
          element: (
            <RoleBasedGuard accessibleRoles={['admin']}>
              <ReceiptListPage />
            </RoleBasedGuard>
          )
        },
        {
          path: 'user',
          children: [
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={['admin', 'direttivo']}>
                  <SubscribedGuard>
                    <UserList />
                  </SubscribedGuard>
                </RoleBasedGuard>
              )
            },
            {
              path: 'inactive',
              element: (
                <RoleBasedGuard accessibleRoles={['admin', 'direttivo']}>
                  <SubscribedGuard>
                    <InactiveUserList />
                  </SubscribedGuard>
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SubscribedGuard>
                    <UserCreate />
                  </SubscribedGuard>
                </RoleBasedGuard>
              )
            },
            {
              path: ':id/edit',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SubscribedGuard>
                    <UserCreate />
                  </SubscribedGuard>
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'purchase',
          children: [
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SubscribedGuard>
                    <PurchaseList />
                  </SubscribedGuard>
                </RoleBasedGuard>
              )
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SubscribedGuard>
                    <PurchaseCreate />
                  </SubscribedGuard>
                </RoleBasedGuard>
              )
            },
            {
              path: ':id/edit',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SubscribedGuard>
                    <PurchaseCreate />
                  </SubscribedGuard>
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: 'pin',
          children: [
            { element: <Navigate to="/dashboard/pin/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={['admin', 'direttivo']}>
                  <SubscribedGuard>
                    <PinList />
                  </SubscribedGuard>
                </RoleBasedGuard>
              )
            }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const SetNewPassword = Loadable(lazy(() => import('../pages/authentication/SetNewPassword')));
const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const UserBooking = Loadable(lazy(() => import('../pages/dashboard/UserBooking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const BookingCreate = Loadable(lazy(() => import('../pages/dashboard/BookingCreate')));
const BookingShow = Loadable(lazy(() => import('../pages/dashboard/BookingShow')));

const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const InactiveUserList = Loadable(lazy(() => import('../pages/dashboard/InactiveUserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const PinList = Loadable(lazy(() => import('../pages/dashboard/PinList')));

const PurchaseList = Loadable(lazy(() => import('../pages/dashboard/PurchaseList')));
const PurchaseCreate = Loadable(lazy(() => import('../pages/dashboard/PurchaseCreate')));

const Setting = Loadable(lazy(() => import('../pages/dashboard/Setting')));

const ReceiptListPage = Loadable(lazy(() => import('../pages/dashboard/ReceiptListPage')));

// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
