import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  transactionList: [],
  transactionListCount: 0,
  receiptList: [],
  receiptListCount: 0
};

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE USERS
    getTransactionListSuccess(state, action) {
      state.isLoading = false;
      state.transactionList = action.payload.rows;
      state.transactionListCount = action.payload.count;
    },

    // GET MANAGE USERS
    getReceiptListSuccess(state, action) {
      state.isLoading = false;
      state.receiptList = action.payload.rows;
      state.receiptListCount = action.payload.count;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTransactionList(params = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/transactions', {
        params
      });
      dispatch(slice.actions.getTransactionListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReceiptList(params = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/transactions/receipts', {
        params
      });
      dispatch(slice.actions.getReceiptListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
