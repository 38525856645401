import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  activePin: null,
  pinList: [],
  pinListCount: 0
};

const slice = createSlice({
  name: 'pin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE USERS
    getPinListSuccess(state, action) {
      state.isLoading = false;
      state.activePin = action.payload.active;
      state.pinList = action.payload.rows;
      state.pinListCount = action.payload.count;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPinList(q = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/pins', {
        params: q
      });
      dispatch(slice.actions.getPinListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
